// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GameList.css */
.game-card {
    transition: transform 0.3s ease;
}

.game-card:hover {
    transform: scale(2.1); /* Scale the card on hover */
}

.game-image-container {
    overflow: hidden;
}

.game-image-container:hover .game-image {
    transform: scale(1.7); /* Scale the image on hover */
}

.game-image {
    transition: transform 0.3s ease;
    width: 100%;
    height: auto;
}
.modal-content {
    transition: all 0.3s ease-in-out;
}

.modal-lg {
    max-width: 800px !important; /* Adjust as needed */
}

.modal-sm {
    max-width: 400px !important; /* Adjust as needed */
}

@keyframes shrinkModal {
    from {
        width: 100%;
        height: 100%;
    }
    to {
        width: 50%; /* Adjust as needed */
        height: 50%; /* Adjust as needed */
    }
}

.shrinking-modal {
    animation: shrinkModal 0.3s ease-in-out forwards;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/GameList.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB,EAAE,4BAA4B;AACvD;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB,EAAE,6BAA6B;AACxD;;AAEA;IACI,+BAA+B;IAC/B,WAAW;IACX,YAAY;AAChB;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,2BAA2B,EAAE,qBAAqB;AACtD;;AAEA;IACI,2BAA2B,EAAE,qBAAqB;AACtD;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,UAAU,EAAE,qBAAqB;QACjC,WAAW,EAAE,qBAAqB;IACtC;AACJ;;AAEA;IACI,gDAAgD;IAChD,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":["/* GameList.css */\n.game-card {\n    transition: transform 0.3s ease;\n}\n\n.game-card:hover {\n    transform: scale(2.1); /* Scale the card on hover */\n}\n\n.game-image-container {\n    overflow: hidden;\n}\n\n.game-image-container:hover .game-image {\n    transform: scale(1.7); /* Scale the image on hover */\n}\n\n.game-image {\n    transition: transform 0.3s ease;\n    width: 100%;\n    height: auto;\n}\n.modal-content {\n    transition: all 0.3s ease-in-out;\n}\n\n.modal-lg {\n    max-width: 800px !important; /* Adjust as needed */\n}\n\n.modal-sm {\n    max-width: 400px !important; /* Adjust as needed */\n}\n\n@keyframes shrinkModal {\n    from {\n        width: 100%;\n        height: 100%;\n    }\n    to {\n        width: 50%; /* Adjust as needed */\n        height: 50%; /* Adjust as needed */\n    }\n}\n\n.shrinking-modal {\n    animation: shrinkModal 0.3s ease-in-out forwards;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
