import React, { useEffect, useState, useRef } from 'react';
import { getAllGames, getGameDetails } from '../api'; // Assuming you have this function to get game details
import { Card, Container, Row, Col, Modal, Button } from 'react-bootstrap';
import 'assets/css/GameList.css'; // Import CSS file for styles

const GameList = () => {
    const [games, setGames] = useState([]);
    const [displayedGames, setDisplayedGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const containerRef = useRef(null);
    const [emailMode, setEmailMode] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const data = await getAllGames();
                setGames(data);
            } catch (error) {
                console.error('Error fetching games:', error);
            }
        };

        fetchGames();
    }, []);

    useEffect(() => {
        setDisplayedGames(games);
    }, [games]);

    const handleScroll = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
    };

    const handleCardClick = async (gameId) => {
        try {
            const gameDetails = await getGameDetails(gameId);
            setSelectedGame(Array.isArray(gameDetails) ? gameDetails[0] : gameDetails);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching game details:', error);
        }
    };

    const handleSendEmail = async () => {
        if (!userEmail) {
            alert('Please enter an email address.');
            return;
        }

        try {
            const response = await fetch('/api/sendemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: userEmail.toLowerCase(), // Use the userEmail state
                    gameDetails: selectedGame, // Send selectedGame details
                }),
            });

            if (response.ok) {
                //alert('Email sent successfully!');
                handleCloseModal();
            } else {
               // alert('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('An error occurred while sending the email.');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedGame(null);
    };

    const handleEmailChange = (e) => {
        console.log('Email input changed:', e.target.value); // Debugging line
        setUserEmail(e.target.value);
    };

    return (
        <div>
            <div
                className="game-list-container"
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                    gap: '20px',
                    overflowX: 'scroll',
                    padding: '20px',
                }}
                ref={containerRef}
            >
                <Container>
                    <Row xs={1} sm={2} md={3} lg={4} xl={5}>
                        {displayedGames.map((game) => (
                            <Col key={game.id}>
                                <Card className="game-card" style={{ height: '30vh', display: 'flex', flexDirection: 'column' }} onClick={() => handleCardClick(game.id)}>
                                    <div className="game-image-container" style={{ flex: '1', overflow: 'hidden' }}>
                                        <Card.Img className="game-image" variant="top" src={game.imageUrl} style={{ objectFit: 'cover', height: '100%' }} />
                                    </div>
                                    <Card.Body style={{ flex: 'none' }}>
                                        <Card.Title>{game.name}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>

            {selectedGame && (
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    dialogClassName={emailMode ? 'shrinking-modal' : ''} // Apply the CSS class
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{emailMode ? 'Enter Your Email' : selectedGame.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {emailMode ? (
                            <>
                                <p>Please enter your email address to get more information about this game:</p>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    style={{ width: '100%', padding: '10px', marginBottom: '15px' }}
                                    value={userEmail}
                                    onChange={handleEmailChange}
                                />
                            </>
                        ) : (
                            <>
                                <img src={selectedGame.imageUrl} alt={selectedGame.name} style={{ width: '100%', marginBottom: '15px' }} />
                                <p><strong>Description:</strong> {selectedGame.description}</p>
                                <p><strong>Genre:</strong> {selectedGame.genre || 'N/A'}</p>
                                <p><strong>Release Date:</strong> {selectedGame.created_at ? new Date(selectedGame.created_at).toLocaleDateString() : 'N/A'}</p>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {!emailMode && (
                            <Button variant="primary" onClick={() => setEmailMode(true)} style={{ marginRight: 'auto' }}>
                                Select this Game
                            </Button>
                        )}
                        {emailMode && (
                            <Button variant="primary" onClick={handleSendEmail} style={{ marginRight: '10px' }}>
                                Send
                            </Button>
                        )}
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default GameList;
