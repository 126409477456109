// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import ReactDOM from "react-dom/client";
import {  Button, Carousel,  Modal} from "reactstrap";

import axios from 'axios';

import "./assets/css/app.css";

import NavBar from "./components/navbar/navbar.js";
import Header from "./components/header/header.js"

import GameList from './components/GameList';


function App() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

    return (
      <div className="App">
        <NavBar />
        <Header />
        <Router>
            
            <Routes>
                  <Route path="/" element={<GameList />} /> 
                     {/* Add other routes here */}
                </Routes>
            
        </Router>     
        </div>
    );
}

export default App;
