// src/api.js
import axios from 'axios';

const API_URL = '/api';

export const createUser = async (email, password) => {
    const response = await axios.post(`${API_URL}/users`, { email, password });
    return response.data;
};

export const createGame = async (name, description) => {
    const response = await axios.post(`${API_URL}/games`, { name, description });
    return response.data;
};

export const createGameSession = async (gameId, userId, sessionToken) => {
    const response = await axios.post(`${API_URL}/game_sessions`, { game_id: gameId, user_id: userId, session_token: sessionToken });
    return response.data;
};

export const getAllGames = async () => {
    const response = await axios.get(`${API_URL}/games`);
    return response.data;
};

export const getGameDetails = async (gameId) => {
    const response = await axios.get(`/api/games/${gameId}`);
    return response.data;
};

export const getGameSessionByToken = async (token) => {
    const response = await axios.get(`${API_URL}/game_sessions/${token}`);
    return response.data;
};
